

/*
*******************************************
*******************************************
Template Name: carcare
Template URL: https://www.themecodersit.com/demo/carcare
Description: Carcare - Car rent service
\Version: 1.0
* This file contains the styling for the actual theme, this
is the file you need to edit to change the look of the
theme.
This files contents are outlined below >>>>
*** SEARCH EACH SECTION AS IT COMMENTS, YOU WILL GET THE RIGHT SECTION.
*******************************************
*******************************************
==== CSS INDEX
01 - IMPORTED CSS
02 - DEFAULT CSS
03 - NAVBAR CSS
04 - HEADER CSS
05 - FIND CAR CSS
06 - ABOUT CSS
07 - SERVICE
08 - OFFERS CSS
09 - CLIENTS CSS
10 - TEAM CSS
11 - CAR BOOKING CSS
12 - BLOG CSS
13 - CONTACT CSS
14 - FOOTER CSS
15 - PRELOADER CSS
*/


/*================================================
01 - IMPORTED CSS
==================================================*/

@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700,800');
@import url('https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700,800');
@import url('https://fonts.googleapis.com/css?family=Oswald:400,500,600,700');
@import url('https://fonts.googleapis.com/css?family=Raleway:400,500,600,700,800,900');

/*================================================
02 - DEFAULT CSS
==================================================*/

body {
	background-color: #fff;
	// color: #6f6f6f;
	font-family: "Raleway", sans-serif;
	font-size: 14px;
	font-weight: 400;
	line-height: 28px;
	overflow-wrap: break-word;
	word-break: normal;
	padding-bottom:60px;
	max-width: 100%;
	overflow-x: hidden;
}

html,
body {
	max-width: 100%;
    overflow-x: hidden;
}

img {
	max-width: 100%;
	height: auto
}

p {
	margin: 0
}

ul,
ol {
	margin: 0;
	padding: 0
}

li {
	list-style-type: none;
}

a {
	-webkit-transition: all 0.4s ease 0s;
	transition: all 0.4s ease 0s;
	color: #6b739c
}

h1 {
	font-size: 35px;
	font-weight: 800;
	line-height: 1;
	color: rgb(50, 50, 50);
}

// * {
//     border: 2px solid red;
// }

.carcare-btn:before {
	background: #000 none repeat scroll 0 0;
	border: 2px solid #000;
	bottom: 0;
	content: "";
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
	z-index: -1;
	width: 100%;
	border-radius: 3px
}

.carcare-btn:hover:before {
	-webkit-transform: scaleX(1);
	transform: scaleX(1);
	-webkit-transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
	transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
}

.carcare-btn {
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	background: @primary-color none repeat scroll 0 0;
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	-webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	color: #fff !important;
	display: inline-block;
	padding: 13px 30px;
	position: relative;
	font-family: "Montserrat", sans-serif;
	border-radius: 4px;
	font-weight: 700;
	font-size: 14px;
	text-transform: uppercase;
	-webkit-transform: translateZ(0px);
	transform: translateZ(0px);
	-webkit-transition-duration: 0.5s;
	transition-duration: 0.5s;
	-webkit-transition-property: color;
	transition-property: color;
	vertical-align: middle;
}

.site-heading {
	margin-bottom: 30px;
	width: 60%;
	margin: 0 auto;
	text-align: center
}

.site-heading h4 {
	font-size: 20px;
	color: @primary-color;
	margin-bottom: 10px;
	display: block;
	font-weight: 500;
	text-transform: capitalize;
	font-family: "Rubik", sans-serif;
}

.site-heading h2 {
	font-size: 40px;
	color: #001238;
	letter-spacing: 1px;
	margin-bottom: 15px;
	-webkit-transition: all 0.4s ease 0s;
	transition: all 0.4s ease 0s;
	display: inline-block;
	text-transform: capitalize;
	font-family: "Poppins", sans-serif;
	font-weight: 600;
}

.section_100 {
	padding: 100px 0;
}

.section_70 {
	padding: 70px 0;
}

.section_50 {
	padding: 50px 0;
}

.section_15 {
	padding: 15px 0
}

.pad-right {
	padding-right: 0
}

.pad-left {
	padding-left: 0
}

.section_t_100 {
	padding-top: 100px
}

.section_t_50 {
	padding-top: 50px
}

.section_b_70 {
	padding-bottom: 70px
}

.section_b_80 {
	padding-bottom: 80px
}

.row {
	margin: 0px;
}

.col_space {
	padding-left: 0px
}


/*================================================
03 - NAVBAR CSS
==================================================*/

nav.navbar {
	position: absolute;
	width: 100%;
	top: 0;
	z-index: 999;
	height: 80px;
	padding-top: 20px;
	padding-bottom: 20px;
	-webkit-transition: all .5s ease;
	transition: all .5s ease;
}

nav.navbar .navbar-toggler {
	border: 0;
}

nav.navbar .nav-item {
	font-size: 13px;
	font-weight: 400;
	padding-right: 13px;
	padding-left: 13px;
	color: #fff;
	text-transform: uppercase;
}

nav.navbar .nav-item:first-child {
	padding-left: 0;
}

nav.navbar .nav-item:last-child {
	padding-right: 0;
}

nav.navbar .nav-item .nav-link {
	color: #fff;
	position: relative;
	text-decoration: none;
	padding: 0;
	font-weight: 600;
	font-family: 'Montserrat', sans-serif;
}

nav.navbar .nav-item .nav-link:before {
	content: "";
	position: absolute;
	width: 100%;
	height: 2px;
	bottom: -3px;
	left: 0;
	background-color: #fff;
	visibility: hidden;
	-webkit-transform: scaleX(0);
	transform: scaleX(0);
	-webkit-transition: all 0.3s ease-in-out 0s;
	transition: all 0.3s ease-in-out 0s;
}

nav.navbar .nav-item .nav-link.active:before {
	visibility: visible;
	-webkit-transform: scaleX(1);
	transform: scaleX(1);
}

nav.fixed-top {
	position: fixed;
	padding-top: 13px;
	padding-bottom: 13px;
	background-color: black;
	box-shadow: 0px 0px 18px 1px rgba(0, 0, 0, 0.1);
}

.navbar-brand.logo h1 {
	color: #fff;
	font-family: 'Oswald', sans-serif;
	font-weight: 600;
	text-transform: uppercase;
	font-size: 24px;
	margin: 0
}

nav.fixed-top .navbar-brand.logo h1 {
	color: #fff
}

nav.fixed-top .navbar-brand.logo h1 span {
	color: #fff
}

.navbar-brand.logo h1 span {
	background: @primary-color;
	width: 30px;
	display: inline-block;
	text-align: center;
	margin-right: 5px;
	border-radius: 5px;
}

nav.fixed-top .logo {
	color: #000;
}

nav.fixed-top .nav-item .nav-link {
	color: white !important;
}

nav.fixed-top .logo:focus,
nav.fixed-top .logo:hover {
	color: #000;
}

nav.fixed-top .navbar-toggler .navbar-toggler-icon {
	background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgb(230, 172, 0)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

nav.fixed-top .nav-item {
	color: #000;
}

nav.fixed-top .nav-item .nav-link:before {
	background-color: @primary-color;
}

.navbar-text{
	color: white;
}


/*================================================
04 - HEADER CSS
==================================================*/

.header_area {
	// background: url(./images/carbg_xl.jpg) no-repeat center center fixed;
	background-color: #efefef;
	// background-size: cover;
	// -webkit-background-size: cover;
	// -moz-background-size: cover;
	// -o-background-size: cover;
	height: 100vh;
	position: relative;
	overflow-x: hidden;
}

.header_area:before {
	position: absolute;
	content: "";
	top: 0;
	width: 100%;
	left: 0;
	height: 100%;
	// background: rgba(0, 0, 0, 0.9) none repeat scroll 0 0;
	opacity: 0.75;
	overflow-x: hidden;
}

.header_content {
	height: 100vh;
	width: 100%;
}

.banner-welcome>h1 {
	color: #fff;
	font-size: 50px;
	font-weight: 900;
	margin-bottom: 20px;
}

.banner-welcome>h1 span {
	padding-bottom: 20px;
	color: @primary-color;
}

.banner-welcome {
	position: absolute;
	top: 25%;
	width: 100%;
}

button.navbar-toggler {
	background: #fff;
	margin-left: 5px
}

.typed-static {
	color: #d6d6d6
}

.caption-inner span {
	color: #fff;
	font-size: 30px;
	font-weight: 400;
}

.ah-headline.clip .ah-words-wrapper:after {
	background-color: #fff
}

.ah-words-wrapper b {
	margin-left: 5px
}


/*================================================
05 - FIND AREA CSS
==================================================*/

.carcare-find-area {
	margin-top: -75px;
}

.find-box {
	margin-top: 75px;
	background: white;
	border-radius: 10px;
	padding: 30px;
	color: black;
	text-align: left;
	position: relative;
	box-shadow: 3px 0 29px rgba(0, 0, 0, 0.26);
}

.find-box h3{
	color: black;
	font-size: 30px;
	font-weight: 900;
}

.find-box span {
	color: @primary-color
}


.find-box p {
	font-weight: 450;
}

.find-text {
	margin-top: 32px;
}



.find-text h3 {
	color: #fff;
	font-size: 33px;
	font-weight: 600;
	text-transform: capitalize;
	font-family: "Poppins", sans-serif;
	line-height: 40px;
}

.find-form p input {
	width: 100%;
	border: 2px solid #f0f0ff;
	padding: 5px 10px;
	height: 45px;
	color: #111;
	-webkit-transition: all 0.4s ease 0s;
	transition: all 0.4s ease 0s
}

.find-form p input:focus {
	border: 2px solid @primary-color
}

.find-form .nice-select {
	width: 100%;
	border: 2px solid #f0f0ff;
	height: 45px;
	-webkit-transition: all 0.4s ease 0s;
	transition: all 0.4s ease 0s;
	border-radius: 0
}

.find-form .nice-select .list {
	top: 100%;
	left: 0px;
	margin: 0px;
	min-width: 100%;
	border-radius: 0px;
}

.find-form form p {
	margin: 15px 0;
}

.datepicker {
	z-index: 9999
}

.datepicker {
	border: 2px solid #f0f0ff;
	margin-top: -5px;
}

.datepicker .cell.day.selected,
.datepicker .cell.month.selected,
.datepicker .cell.year.selected,
.datepicker .cell.decade.selected {
	background: #ca3d26 none repeat scroll 0 0;
	color: #fff;
}

.datepicker .cell.day:hover,
.datepicker .cell.month:hover,
.datepicker .cell.year:hover,
.datepicker .cell.decade:hover {
	background: #ca3d26 none repeat scroll 0 0;
	color: #fff;
}

.popover.clockpicker-popover.bottom.clockpicker-align-left {
	border: 2px solid #f0f0ff;
}

.text-primary,
.clockpicker-popover .popover-title {
	color: #ef4836;
}

.datepicker .row.header a {
	margin: 0 3px;
	cursor: pointer;
	display: block;
	text-align: center;
	width: 100%;
}

.datepicker .day {
	font-size: 14px;
	text-align: center
}

.popover-title span {
	color: #ef4836
}

.popover-title .text-primary {
	color: #111 !important
}

.clockpicker input {
	font-size: 14px;
	color: #7c8a97
}

.clockpicker input:focus {
	box-shadow: 0 0 0 rgba(0, 123, 255, 0.25);
	border: 2px solid #f0f0ff;
	border-radius: 0
}


/*================================================
06 - ABOUT AREA CSS
==================================================*/

.about-left h4 {
	font-size: 20px;
	color: @primary-color;
	margin-bottom: 10px;
	display: block;
	font-weight: 500;
	text-transform: capitalize;
	font-family: "Rubik", sans-serif
}

.about-left h2 {
	font-size: 40px;
	color: #001238;
	letter-spacing: 1px;
	margin-bottom: 15px;
	-webkit-transition: all 0.4s ease 0s;
	transition: all 0.4s ease 0s;
	display: inline-block;
	text-transform: capitalize;
	font-family: "Poppins", sans-serif;
	font-weight: 600;
}

.single-list {
	padding-left: 10px;
}

.single-list-bd-top {
	border-top: 1px solid red;
}

.single-list-bd-left {
	border-left: 1px solid red;
}

.about-right {
	margin-top: 30px;
}

.about-right .button {
	color: @primary-color
}


/*==================================
 07- Service section              
==================================--*/

.carcare-service-area {
	background: #fbfbfd none repeat scroll 0 0
}

.carcare-service-area .service-thumb {
	margin-bottom: 30px;
	margin-top: 20px;
}

.carcare-service-area .service-thumb h4 {
	color: #000;
}

.carcar-service-area .cta .a {
	color: @primary-color;
}

.carcare-service-area .thumb-icon {
	width: 70px;
	height: 70px;
	border-radius: 50%;
	text-align: center;
	margin: 0 auto;
	margin-bottom: 20px;
	transition: ease-in-out 0.3s;
	-webkit-transition: ease-in-out 0.3s;
	-moz-transition: ease-in-out 0.3s;
	-ms-transition: ease-in-out 0.3s;
}

.carcare-service-area .col-md-4:hover .thumb-icon {
	-moz-transform: scaleX(-1);
	-o-transform: scaleX(-1);
	-webkit-transform: scaleX(-1);
	transform: scaleX(-1);
	filter: FlipH;
	-ms-filter: "FlipH";
}

.thumb-img {
	border: 1px dashed #ffcc33;
	padding: 5px;
	border-radius: 3px;
}

.carcare-service-area .fa {
	font-size: 24px;
	margin-top: 22px;
	color: #fff;
}


/*================================================
08 - HOT OFFERS AREA CSS
==================================================*/

#offerTab {
	text-align: center;
	width: 100%;
	display: block;
	border: medium none;
	margin: 30px 0
}

#offerTab li {
	display: inline-block;
	margin: 0 5px;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
	border: medium none
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
	border: medium none
}

.nav-tabs .nav-link {
	border: medium none;
}

.cta-btn {
	display: inline-block;
	height: 40px;
	margin-left: 20px;
}

#offerTab li.nav-item a.nav-link {
	display: inline-block;
	text-transform: uppercase;
	padding: 7px 25px;
	font-weight: 500;
	color: white;
	font-size: 14px;
}

#offerTab li.nav-item a.nav-link.active {
	display: inline-block;
	text-transform: uppercase;
	padding: 7px 25px;
	font-weight: 500;
	color: #fff;
	font-size: 14px;
	background: @primary-color
}

#offerTab li.nav-item a.nav-link:hover {
	background: @primary-color none repeat scroll 0 0;
	color: #fff;
}

.single-offers {
	text-align: center;
	padding: 20px;
	border-radius: 10px;
	margin-top: 30px;
	background: #EEEEEE
}

.offer-image {
	width: 235px;
	margin: 0 auto;
	display: block;
}

.offer-image img {
	width: 100%;
}

.offer-text {
	margin-top: 20px
}

.offer-text h3 {
	font-size: 23px;
	color: #001238;
	letter-spacing: 1px;
	margin-bottom: 10px;
	-webkit-transition: all 0.4s ease 0s;
	transition: all 0.4s ease 0s;
	display: inline-block;
	text-transform: capitalize;
	font-family: "Poppins", sans-serif;
	font-weight: 600;
}

.offer-text h4 {
	color: #020202;
	font-size: 18px;
	text-transform: capitalize;
	margin-top: 8px;
}

.offer-text h4 span {
	text-transform: capitalize;
	color: @primary-color;
	font-size: 16px;
}

.offer-action {
	text-align: center;
	position: relative;
	z-index: 1;
	overflow: hidden;
	width: 100%;
	margin: 25px auto 0;
	-webkit-transition: all 0.4s ease 0s;
	transition: all 0.4s ease 0s;
}

.offer-action a {
	border-radius: 5px;
	display: inline-block;
	text-transform: uppercase;
	padding: 7px 15px;
	font-weight: 500;
	color: white;
	background-color: @primary-color;
	font-size: 14px;
	width: 47%;
}

.offer-action a:hover{
	text-decoration: none;
	color: @primary-color;
	font-weight: 600;
	background-color: white;
}

.offer-text ul {
	display: inline-block;
	text-align: left;
	margin-top: 10px;
}

.offer-text ul li {
	display: inline-block;
	text-align: left;
	font-size: 14px;
}

.offer-text ul li i {
	/*margin-right: 5px;*/
	color: @primary-color
}


/*============================================
09 -Clients
==============================================*/

.testimonial_area {
	background: #fbfbfd none repeat scroll 0
}

.right_test {
	background: #EEEEEE none repeat scroll 0 0;
	padding: 50px 20px;
	border-radius: 10px;
	text-align: center
}

.right_test p {
	color: #ffffff
}

.triangle-down {
	width: 0;
	height: 0;
	border-left: 40px solid transparent;
	border-right: 40px solid transparent;
	border-top: 25px solid #EEEEEE;
	text-align: center;
	padding-bottom: 0px
}

.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
	background: @primary-color
}


/*================================================
11 - TEAM CSS
==================================================*/

.team-img {
	overflow: hidden;
	position: relative;
	text-align: center;
	transition: .5s
}

.team-img:hover .team-overlay {
	bottom: 0;
	opacity: .9
}

.team-img img {
	width: 100%
}

.team-overlay {
	position: absolute;
	width: 100%;
	height: 40%;
	bottom: -100%;
	background: @primary-color;
	transition: .5s
}

.team-details {
	padding: 30px
}

.team-details h5 {
	font-weight: 600;
	color: @primary-color;
}

.team-info {
	padding: 10px;
	color: #fff
}

.team-info ul li {
	display: inline-block;
	margin-right: 10px;
	margin-top: 10px
}

.team-info ul li i {
	background: #333;
	border-radius: 30px;
	color: #fff;
	height: 40px;
	padding: 13px;
	width: 40px
}


/*================================================
11 - CAR BOOKING CSS
==================================================*/

.carcare-booking-form {
	background-attachment: fixed;
	// background-image: url("./images/banner.jpg");
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	position: relative;
}

.carbooking-overlay {
	background: rgba(0, 0, 0, 0.71) none repeat scroll 0 0;
	height: 100%;
	position: absolute;
	top: 0;
	width: 100%;
}

.rental-tag {
	display: inline-block;
	padding: 5px 15px;
	line-height: 20px;
	text-transform: uppercase;
	background: @primary-color;
	color: #fff;
	font-weight: 500;
	font-size: 14px;
	border-radius: 3px;
	margin-bottom: 5px;
}

.car-booking-right h3 {
	font-size: 30px;
	color: #ffffff;
	letter-spacing: 1px;
	margin-bottom: 10px;
	-webkit-transition: all 0.4s ease 0s;
	transition: all 0.4s ease 0s;
	display: block;
	text-transform: capitalize;
	font-family: "Poppins", sans-serif;
	font-weight: 600;
	line-height: 45px;
}

.car-booking-right h4 {
	color: #ffffff;
	font-size: 18px;
	text-transform: capitalize;
	margin-top: 0px;
	display: inline-block;
}

.car-booking-right h4 span {
	text-transform: capitalize;
	color: #ffffff;
	font-size: 16px;
}

.price-rent {
	display: inline-block;
	margin-right: 15px;
}

.car-rating {
	display: inline-block;
}

.car-rating ul {
	display: inline-block;
}

.car-rating ul li {
	display: inline-block;
	margin-right: 1px;
	color: #ffffff;
}

.car-rating p {
	display: inline-block;
	margin-left: 5px;
	color: #ffffff;
	text-transform: capitalize;
}

.price-rating {
	margin-bottom: 20px;
}

.car-features ul {
	width: 32%;
	float: left;
	margin-top: 20px;
}

.car-features ul li {
	margin: 5px 0;
}

.car-features ul li i {
	margin-right: 5px;
	color: @primary-color;
}

.disclaimertext p {
	font-weight: 500;
	color: white
}

.disclaimertext p a {
	font-weight: 500;
	color: @primary-color;
	text-decoration: none;
}

.single-booking h3,
.booking-right h3 {
	font-size: 23px;
	color: #ffffff;
	letter-spacing: 1px;
	margin-bottom: 10px;
	-webkit-transition: all 0.4s ease 0s;
	transition: all 0.4s ease 0s;
	display: block;
	text-transform: capitalize;
	font-family: "Poppins", sans-serif;
	font-weight: 600;
	line-height: 28px;
	padding-bottom: 10px;
	border-bottom: 1px solid #f0f0ff
}

.single-booking form label {
	display: block;
	color: #001238;
	font-weight: 500;
	font-size: 14px;
	text-transform: capitalize;
}

.single-booking form p {
	margin-top: 15px
}

.single-booking form p select {
	width: 100%;
	border: 2px solid #f0f0ff;
	padding: 5px 10px;
	height: 45px;
	color: #111;
	border-radius: 5px;
	-webkit-transition: all 0.4s ease 0s;
	transition: all 0.4s ease 0s;
}


.single-booking form p input {
	width: 100%;
	border: 2px solid #f0f0ff;
	padding: 5px 10px;
	height: 45px;
	color: #111;
	border-radius: 5px;
	-webkit-transition: all 0.4s ease 0s;
	transition: all 0.4s ease 0s;
}

.single-booking form p textarea {
	width: 100%;
	border: 2px solid #f0f0ff;
	padding: 5px 10px;
	height: 120px;
	color: #111;
	border-radius: 5px;
	-webkit-transition: all 0.4s ease 0s;
	transition: all 0.4s ease 0s;
}

.single-booking form p .nice-select {
	width: 100%;
	border: 2px solid #f0f0ff;
	height: 45px;
	-webkit-transition: all 0.4s ease 0s;
	transition: all 0.4s ease 0s;
	border-radius: 0;
}

.single-booking form p input:focus {
	border: 2px solid @primary-color;
	border-radius: 5px;
}

.single-booking {
	margin-bottom: 30px;
}

.single-booking:last-child {
	margin-bottom: 0px;
}

.carcare-payment .payment {
	display: block;
	position: relative;
	float: left;
	width: 100%;
	height: auto;
}

.carcare-payment .payment input[type=radio] {
	position: absolute;
	visibility: hidden;
}

.carcare-payment .payment label {
	display: inline-block;
	position: relative;
	padding: 0 0 0 30px;
	margin: 10px auto;
	z-index: 9;
	cursor: pointer;
	-webkit-transition: all 0.25s linear;
	color: #ffffff;
	font-weight: 500;
}

.carcare-payment .payment .check {
	display: block;
	position: absolute;
	border: 3px solid #AAAAAA;
	border-radius: 100%;
	height: 20px;
	width: 20px;
	top: 14px;
	left: 0;
	z-index: 5;
	transition: border .25s linear;
	-webkit-transition: border .25s linear;
}

.carcare-payment .payment .check:before {
	display: block;
	position: absolute;
	content: '';
	border-radius: 100%;
	height: 8px;
	width: 8px;
	top: 3px;
	left: 3px;
	margin: auto;
	transition: background 0.25s linear;
	-webkit-transition: background 0.25s linear;
}

.payment p {
	color: #ffffff;
}

input[type="radio"]:checked~.check {
	border: 3px solid @primary-color;
}

input[type="radio"]:checked~.check:before {
	background: @primary-color;
}

input[type="radio"]:checked~label {
	color: @primary-color;
}

.payment img {
	float: right;
	margin-top: 15px;
}

.action-btn {
	text-align: right
}

.action-btn a.carcare-btn {
	color: @primary-color;
	margin: 30px 0 0 0
}

.action-btn a.carcare-btn:hover {
	color: #fff;
}


/** ============================
12 - Blog  
===============================**/

.blog-3 {
	margin-bottom: 30px;
	border-radius: 0;
	border: none;
	background: #fff;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.blog-3:hover {
	box-shadow: 0 0 35px rgba(0, 0, 0, 0.1);
}

.blog-3 .blog-photo {
	position: relative;
}

.blog-3 .blog-photo:before {
	content: "";
	top: 0;
	position: absolute;
	height: 100%;
	width: 100%;
	z-index: 0;
	background: linear-gradient(to top, rgba(47, 45, 45, 0.6) 0%, rgba(0, 0, 0, 0.32) 35%, rgba(2, 2, 2, 0) 60%, rgba(0, 0, 0, 0) 100%);
}

.blog-3 .blog-photo img {
	width: 100%;
}

.blog-3 .detail {
	padding: 25px;
	text-align: left;
}

.blog-3 .detail h3 {
	font-size: 20px;
	font-weight: 600;
	margin-bottom: 10px;
	line-height: 27px;
}

.blog-3 .b-btn:hover {
	color: #535353;
}

.blog-3 .post-meta {
	position: absolute;
	bottom: 15px;
	font-size: 15px;
	border-radius: 5px;
	width: 90%;
	margin: 0 5%;
	padding: 7px 0;
	text-align: center;
	background: rgba(0, 0, 0, 0.36);
}

.blog-3 .post-meta span {
	margin: 0 15px 0 0;
	display: inline-block;
	font-size: 14px;
	color: #fff;
	font-weight: 500;
	line-height: 26px;
}

.blog-3 .detail h3 a:hover {
	color: #535353;
}

.blog-3 .detail h3 a {
	color: #383737;
}

.blog-3 .post-meta span i {
	font-size: 14px;
	margin-right: 5px;
	font-weight: 600;
}

.blog-3 .date-box-2 {
	position: absolute;
	top: 15px;
	font-size: 14px;
	font-weight: 500;
	font-family: 'Montserrat', sans-serif;
	padding: 20px 15px 25px;
	text-align: center;
	right: 20px;
	color: #fff;
	background: rgba(0, 0, 0, 0.36);
	border-radius: 5px 5px 0 0;
}

.blog-3 .db-2 {
	clip-path: polygon(0 0, 100% 0, 100% 75%, 50% 100%, 0 75%);
}


/*================================================
13 - CONTACT CSS
================================================*/

.contact_area {
	background: white;
}

.contact_area .info {
	padding-top: 30px;
}

.contact_area .info .item {
	overflow-wrap: break-word;
	word-wrap: break-word;
	text-align: left;
	margin-bottom: 30px;
}

.contact_area .info .item .cont h6 {
	color: @primary-color;
	margin-bottom: 5px;
	font-size: 11px;
	font-weight: 800;
	text-transform: uppercase;
	letter-spacing: 2px;
}

.form-control {
	color: #fff;
	width: 100%;
	padding: 15px;
	border: 0;
	border: 1px solid #222220 !important;
	border-radius: 10px;
}

.form-control:focus {
	box-shadow: none;
	-webkit-box-shadow: none;
	-mos-box-shadow: none;
	border-color: @primary-color;
	transition: all 1s;
	-webkit-transition: all 1s;
	-mos-transition: all 1s;
}

.contact_area .form textarea {
	height: 160px;
	max-height: 160px;
	max-width: 100%;
	border-radius: 10px;
}


/*================================================
14 - FOOTER CSS
================================================*/

footer {
	background: black;
	padding: 20px 0;
	width: 100%;
}

.page-content {
	padding-bottom: 80px;
}

footer p {
	margin-top: 10;
	font-weight: 400;
	color: @primary-color;
	font-size: 12px;
}

footer a {
	color: @primary-color;
}

footer p a:hover {
	color: white;
	text-decoration: none;
}


.wizard-controls label {
	font-family: "Raleway", sans-serif;
	color: @primary-color;
	font-weight: 500;
}

.home-banner-button {
	margin-top: 50px;
	background-color: rgb(255, 255, 255);
	border-radius: 10px;
	min-height: 40px;
	vertical-align: middle;
	line-height: 40px;
	text-align: center;

}

.home-banner-button:hover {
	background-color: @primary-color;
}

.home-banner-button p {
	color: @primary-color;
	margin: auto;
	font-weight: 500;
	letter-spacing: 0.2;
	text-transform: uppercase;
	cursor: pointer;
	font-family: "Raleway", sans-serif;
}

.home-banner-button p:hover {
	color: white;
}

.wizard-overview {
	margin: 30px;
}

.wizard-overview p {
	font-weight: 500;
}

.wizard-overview a {
	color: @primary-color;
}

.wizard-overview a:hover {
	text-decoration: none;
}

.steps-content {
	min-height: 200px;
	margin-top: 16px;
	padding-top: 30px;
	text-align: center;
	border: 0px dashed #e9e9e9;
	border-radius: 2px;
}

.steps-action {
	margin-top: 24px;
}

.ant-steps-item-title {
    position: relative;
    display: inline-block;
    padding-right: 16px;
    color: @primary-color;
    font-size: 16px;
	line-height: 32px;
	font-weight: 500;
	font-family: "Raleway", sans-serif;
}

.ant-list-item-main {
	align-items: flex-start;
}

.ant-card-meta-title {
	color: @primary-color;
	text-transform: uppercase;
}

.ant-list-vertical .ant-list-item-meta-title {
	color: @primary-color
}

.desktop-hide{
	visibility: hidden;
	height: 0;
}

.price {
	font-weight: 600;
	font-size: 12px;
	text-align: left;
}

.description {
	font-weight: 300;
	font-size: 11px;
	text-align: left;
}

.price span{
	font-size: 8px;
	color: gray;
}

.distance{
	font-weight: 600;
	color: @primary-color;
	text-align: right;
}

.distance span{
	font-size: 10px;
	color: gray;
}

.carousel .carousel-status {
    color: @primary-color !important;
}

.carousel .control-dots .dot {
	background-color: @primary-color !important;
}

.reservation-banner {
	border-radius: 5px;
	background-color: rgba(0,0,0,0.1);
	padding: 30;
	min-height: 70px;
	line-height: 70px;
}

.tiny-text {
	font-size: 10px;
	color: gray;
}
@primary-color: #e6ac00;