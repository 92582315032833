

/*
**********

Responsive styles


/*================ Normal desktop :992px.==================== */

@media (min-width: 992px) and (max-width: 1169px) {
	.site-heading>h2 {
		font-size: 30px
	}
}


/*================ Tablet desktop :768px.==================*/

@media (min-width: 768px) and (max-width: 991px) {
	.navbar-collapse {
		background-color: black;
		text-align: left;
	}

	nav.navbar .nav-item .nav-link {
		color: #333
	}
	nav.navbar .nav-item .nav-link::before {
		display: none
	}
	nav.navbar .nav-item:first-child {
		padding-left: 13px;
		margin-top: 15px
	}
	nav.navbar .nav-item:last-child {
		padding-right: 13px;
		margin-bottom: 15px
	}
	nav.navbar .nav-item .nav-link {
		padding: 3px 10px
	}
	#pills-tab {
		width: 100% !important
	}
	.about_right {
		margin-top: 30px
	}
	.contact_area form {
		width: 100%;
	}
}


/*==================  Large Mobile :320px. ===================*/

@media (max-width: 767px) {
	.navbar-collapse {
		background-color: black;
		text-align: left;
	}
	nav.navbar .nav-item .nav-link {
		color: #333
	}
	nav.navbar .nav-item .nav-link::before {
		display: none
	}
	nav.navbar .nav-item:first-child {
		padding-left: 0px;
		margin-top: 15px
	}
	nav.navbar .nav-item:last-child {
		padding-right: 13px;
		margin-bottom: 15px
	}
	nav.navbar .nav-item .nav-link {
		padding: 3px 10px
	}
	.banner-welcome>h3 {
		font-size: 30px;
		font-weight: 600
	}
	.caption-inner span {
		font-size: 18px
	}
	.site-heading>h2 {
		font-size: 24px
	}
	.site-heading>h2::before {
		right: -45px;
	}
	.about_right {
		margin-top: 20px;
	}
	.about_right>h2 {
		font-size: 17px;
		letter-spacing: 0px;
	}
	.nav-pills .nav-item {
		margin: 2px 5px;
		display: block;
		width: 100%;
		text-align: center;
	}
	.site-heading>h3 {
		font-size: 24px
	}
	.footer_s {
		margin: 20px 0 !important
	}
	#pills-tab {
		width: 100% !important
	}
	.res_margin_top {
		margin-top: 30px
	}
	.contact_area form {
		width: 100%;
	}
}


/* ====================== :small mobile.================== */

@media only screen and (min-width: 280px) and (max-width: 767px) {
	.navbar-collapse {
		background-color: black;
		text-align: left;
	}
	nav.navbar .nav-item .nav-link {
		color: #333
	}
	nav.navbar .nav-item .nav-link::before {
		display: none
	}
	nav.navbar .nav-item:first-child {
		padding-left: 13px;
		margin-top: 15px
	}
	nav.navbar .nav-item:last-child {
		padding-right: 13px;
		margin-bottom: 15px
	}
	nav.navbar .nav-item .nav-link {
		padding: 3px 10px
	}
	.banner-welcome>h3 {
		font-size: 30px;
		font-weight: 600
	}
	.caption-inner span {
		font-size: 18px
	}
	.site-heading>h2 {
		font-size: 24px
	}
	.site-heading>h2::before {
		right: -45px;
	}
	.about_right {
		margin-top: 20px;
	}
	.about_right>h2 {
		font-size: 17px;
		letter-spacing: 0px;
	}
	.nav-pills .nav-item {
		margin: 2px 5px;
		display: block;
		width: 100%;
		text-align: center;
	}
	.site-heading>h3 {
		font-size: 24px
	}
	.footer_s {
		margin: 20px 0 !important
	}
	#pills-tab {
		width: 100% !important
	}
	.res_margin_top {
		margin-top: 30px
	}
	.contact_area form {
		width: 100%;
	}
	.about_contact li {
		margin-top: 40px;
	}
	.submit_button {
		margin-bottom: 15px
	}

	.mobile-hide{
		visibility: hidden;
		height: 0;
	}
}


/* ====================== :Portfolio tab.================== */

@media only screen and (min-width: 280px) and (max-width: 462px) {
	nav>div a.nav-item.nav-link.active:after {
		content: "";
		position: relative;
		bottom: -0px;
		left: -10%;
		border: 0px solid transparent;
		border-top-color: @primary-color;
	}
}

@primary-color: #e6ac00;